<template>
  <v-card
  >
    <v-card-title>
      {{$vuetify.lang.t('$vuetify.feeRule.label')}}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form
          ref="form"
        >
          <v-row>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="ruleName"
                :label="$vuetify.lang.t('$vuetify.feeRule.ruleName')"
                disabled
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-select
                v-model="form.ruleType"
                :label="$vuetify.lang.t('$vuetify.feeRule.ruleType')"
                :items="ruleTypeItems"
                :rules="rules.ruleType"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-select
                v-model="form.feeType"
                :label="$vuetify.lang.t('$vuetify.feeRule.feeType')"
                :items="feeTypeItems"
                :rules="rules.feeType"
                item-text="name"
                item-value="value"
                @change="handleFeeTypeChange"
              ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.feeFrequency"
                :append-outer-icon="$vuetify.lang.t('$vuetify.common.time')"
                :label="$vuetify.lang.t('$vuetify.feeRule.feeFrequency')"
                :readonly="form.feeType === 'SERIES_FREQUENCY'"
                :rules="rules.feeFrequency"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.feeValue"
                :append-outer-icon="$vuetify.lang.t('$vuetify.common.yuan')"
                :label="$vuetify.lang.t('$vuetify.feeRule.feeValue')"
                :rules="rules.feeValue"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { snakeToCamel } from '@/utils';

  export default {
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        form: {
          id: null,
          ruleName: null,
          ruleType: null,
          feeType: null,
          feeFrequency: null,
          feeValue: null
        },
        rules: {
          ruleType: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.feeRule.ruleType')])
          ],
          feeType: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.feeRule.feeType')])
          ],
          feeFrequency: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.feeRule.feeFrequency')])
          ],
          feeValue: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.feeRule.feeValue')])
          ]
        },
        ruleTypeItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.ruleType.standard'),
            value: 'STANDARD'
          },{
            name: this.$vuetify.lang.t('$vuetify.ruleType.expert'),
            value: 'EXPERT'
          }

        ],
        feeTypeItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.seriesFrequency'),
            value: 'SERIES_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.combinationPackage'),
            value: 'COMBINATION_PACKAGE'
          }
        ]
      }
    },
    computed: {

      ruleName () {
        let res = null
        if (this.form.ruleType  && this.form.feeType) {
          if ( this.form.feeType === 'SERIES_FREQUENCY') {
            res = `${ this.$vuetify.lang.t('$vuetify.ruleType.' + this.form.ruleType.toLowerCase() ) }${ this.$vuetify.lang.t('$vuetify.serviceFrequency.' + this.snakeToCamel(this.form.feeType.toLowerCase() ) )  }`
          } else {
            if ( this.form.feeFrequency ) {
              res = `${this.form.feeFrequency}${ this.$vuetify.lang.t('$vuetify.common.time') }${ this.$vuetify.lang.t('$vuetify.serviceFrequency.' + this.snakeToCamel(this.form.feeType.toLowerCase() ) )  }`
            }
          }
        }

        return res
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {

      snakeToCamel,

      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
        } else {
          this.resetFrom()
        }
      },
      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.$refs.form.resetValidation()

      },
      handleCancel() {
        this.$emit('cancel', true)
      },

      handleFeeTypeChange (data) {
        if (data === 'SERIES_FREQUENCY' ) {
          this.form.feeFrequency = 1
        } else {
          this.form.feeFrequency = null
        }
      },

      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          data.ruleName = this.ruleName
          if (this.editStatus) {
            data.id = this.form.id
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }
        }
      }
    }
  }
</script>
